import React from "react";
import Layout from "../components/layout.js";
import Section from "../components/section.js";
import Masthead from "../components/masthead.js";

export default () =>
{
    return (<Layout i18n="en" languages={[]} menulinks={[]}>
        <Masthead illustration="/img/undraw/svg-ec2024/undraw_not_found_60pq.svg"
                  title="Error 404"
                  subtitle="Page not found"
                  cta1txt="Go to homepage"
                  cta1url="/"
                  cta2txt="Report an issue"
                  cta2url="https://zumproductions.atlassian.net/servicedesk/customer/portal/1"
                  socialmedia={true}
        />
        <Section>
            <img src="/img/undraw/svg-ec2024/undraw_page_not_found_su7k.svg" alt="" style={{width:300}}/>
        </Section>
    </Layout>);
}
